import iconLogo from "../../assets/images/avatar/link.svg";

const localDataName = 'mateInvoice';
const orderStatusOptions = ['Pending', 'Shipped', 'Delivered'];

const fakedata = [
  {
    key: 1,
    icon:iconLogo,
    votersname: 'Jane Cooper',
    address: '775 Rolling Green Rd.',
    city: 'Naperville',
    phone: '(308) 555-0121',
    age: '24 F',
   
  },
  {
    key: 2,
    icon:iconLogo,
    votersname: 'Jane Coper',
    address: '775 Rolling Green Rd.',
    city: 'Naperville',
    phone: '(308) 555-0121',
    age: '24 F',
  },
  {
    key: 3,
    icon:iconLogo,
    votersname: 'Jane Cooper',
    address: '775 Rolling Green Rd.',
    city: 'Naperville',
    phone: '(308) 555-0121',
    age: '24 F',
  },
  {
    key: 4,
    icon:iconLogo,
    votersname: 'Jane Cooper',
    address: '775 Rolling Green Rd.',
    city: 'Naperville',
    phone: '(308) 555-0121',
    age: '24 F',
  },
  {
    key: 5,
    icon:iconLogo,
    votersname: 'Jane Cooper',
    address: '775 Rolling Green Rd.',
    city: 'Naperville',
    phone: '(308) 555-0121',
    age: '24 F',
  },
  {
    key: 6,
    icon:iconLogo,
    votersname: 'Jane Cooper',
    address: '775 Rolling Green Rd.',
    city: 'Naperville',
    phone: '(308) 555-0121',
    age: '24 F',
  },
  {
    key: 7,
    icon:iconLogo,
    votersname: 'Jane Cooper',
    address: '775 Rolling Green Rd.',
    city: 'Naperville',
    phone: '(308) 555-0121',
    age: '24 F',
  },
  {
    key: 8,
    icon:iconLogo,
    votersname: 'Jane Cooper',
    address: '775 Rolling Green Rd.',
    city: 'Naperville',
    phone: '(308) 555-0121',
    age: '24 F',
  },
  {
    key: 9,
    icon:iconLogo,
    votersname: 'Jane Cooper',
    address: '775 Rolling Green Rd.',
    city: 'Naperville',
    phone: '(308) 555-0121',
    age: '24 F',
  },
  {
    key: 10,
    icon:iconLogo,
    votersname: 'Jane Cooper',
    address: '775 Rolling Green Rd.',
    city: 'Naperville',
    phone: '(308) 555-0121',
    age: '24 F',
  },
  {
    key: 11,
    icon:iconLogo,
    votersname: 'Jane Cooper',
    address: '775 Rolling Green Rd.',
    city: 'Naperville',
    phone: '(308) 555-0121',
    age: '24 F',
  },
];
const newInvoice = {
  orderStatus: 'Pending',
  orderDate: new Date().getTime(),
  currency: '$',
  billTo: '',
  billToAddress: '',
  billFrom: '',
  billFromAddress: '',
  invoiceList: [
    {
      key: 1,
      itemName: '',
      costs: 0,
      qty: 0,
      price: 0,
    },
  ],
  subTotal: 0,
  vatRate: 10,
  vatPrice: 0,
  totalCost: 0,
};
const createDemoData = () => {
  const localData = localStorage.getItem(localDataName);
  if (localData) {
    try {
      const invoices = JSON.parse(localData);
      if (invoices && invoices.length > 0) {
        return invoices;
      }
    } catch (e) {}
  }
  return fakedata;
};

export {
  fakedata,
  createDemoData,
  localDataName,
  newInvoice,
  orderStatusOptions,
};
