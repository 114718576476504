import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import * as serviceWorker from './serviceWorker';
import './index.scss';
import firebase from 'firebase/app';
import notification from '@iso/components/Notification';


ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

if(firebase.messaging.isSupported()) {
	const messaging = firebase.messaging();

	messaging.onMessage((payload) => {
		notification("success",payload.data.body);
	});
}

serviceWorker.unregister();
